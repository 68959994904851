

export const isOfficeHours = async settings => {
  const dt = new Date();
  // Check if daylight savings time
  let dst = false;
  const currentEasternTime = new Date().toLocaleString("en-US", { timeZone: "America/New_York", timeZoneName: "long" });
  if (currentEasternTime.includes("Daylight")) {
    dst = true;
  } else {
    dst = false;
  }


  const offset = dst ? -4 * 60 : -5 * 60;
  //convert from UTC to EST
  dt.setTime(dt.getTime() + dt.getTimezoneOffset() * 60 * 1000);
  const estDate = new Date(dt.getTime() + offset * 60 * 1000);
  const day = estDate.getDay();
  const hours = estDate.getHours();
  const minutes = estDate.getMinutes();

  // ====== HOLIDAYS ======
  const date = estDate.getDate();
  const month = estDate.getMonth(); // 0 indexed. 0 = January
  const year = estDate.getFullYear();

  // Set so that office hours always display on home page
  if (settings?.isHome) {
    return true;
  }

  // closed on Sunday
  if (day === 0) return false;

  // closed on Saturday
  // TEMPORARY: Comment out to stay open on Saturday
  if (day === 6 && !settings?.isHome) return false;

  //Use this optional param to hide phone number on Saturday
  if (day === 6 && (settings?.currentSlug === 'servicios-contables' || settings?.currentSlug == 'entity_formation' || settings?.currentSlug == 'ef-general'
    || settings?.currentSlug == 'ef-geo' || settings?.currentSlug == 'ef-name' || settings?.currentSlug == 'ef-online'
    || settings?.currentSlug == 'ef-general-llc' || settings?.currentSlug == 'ef-geo-llc' || settings?.currentSlug == 'ef-name-llc'
    || settings?.currentSlug == 'ef-general-v2' || settings?.currentSlug == 'ef-geo-dyn' || settings?.currentSlug == 'industry')) return false;

  //2023 Holiday Office Hours
  if (!settings?.isHome) {
    // 5/29/23
    if (date === 29 && month === 4) {
      return false;
    }
    // 7/4/23
    if (date === 4 && month === 6) {
      return false;
    }
    // 9/4/23
    if (date === 4 && month === 8) {
      return false;
    }
    // 11/23/23
    if (date === 23 && month === 10) {
      return false;
    }
    // 12/25/23
    if (date === 25 && month === 11) {
      return false;
    }
  }

  // if (day === 6) {
  //   // Saturday 10am-5pm
  //   if (hours < 10) return false;
  //   if (hours > 16) return false;
  // }


  let sHour, sMin, eHour, eMin;
  // Ignore the office hour from WP for now since all pages has same phone hour except for home page
  if (settings?.isHome) {
    let startTime = undefined;
    let endTime = undefined;
    if (settings) {
      ({ startTime, endTime } = settings);
    }

    const hasStartHour = Object.keys(startTime).includes('hours');
    const hasStartMin = Object.keys(startTime).includes('minutes');
    const hasEndHour = Object.keys(endTime).includes('hours');
    const hasEndMin = Object.keys(endTime).includes('minutes');

    sHour = hasStartHour ? startTime.hours : 9;
    sMin = hasStartMin ? startTime.minutes : 0;
    eHour = hasEndHour ? endTime.hours : 20;
    eMin = hasEndMin ? endTime.minutes : 0;
  } else {
    //Outside of home page office hours 9am - 6pm EST
    sHour = 9;
    sMin = 0;
    eHour = 18;
    eMin = 0;
  }
  var start = sHour * 60 + sMin;
  var end = eHour * 60 + eMin;
  var time = hours * 60 + minutes;


  // check if current time is inclusive to startTime & endTime
  return time >= start && time < end;
};
